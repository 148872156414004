<template>
	<div>
		<SelectContract />
		<SelectContractLabel />
		<template v-if="!isLoading">
			<template v-if="solicitacoes.length > 0">
				<b-card
					no-body
					class="mb-1"
					v-for="(solicitacao, index) in solicitacoes"
					:key="index"
				>
					<b-card-header
						class="py-1 px-2"
						v-b-toggle="'accordion-' + solicitacao.num_proces"
					>
						<b-card-title class="font-medium-1 col-10 px-0">
							{{ solicitacao.descricao }}
						</b-card-title>
						<div class="col-6 col-md-1 d-none d-md-flex justify-content-center">
							<b-badge pill variant="light-success" v-if="solicitacao.is_finished">
								Concluído
							</b-badge>
							<b-badge pill variant="light-danger" v-else-if="solicitacao.is_canceled">
								Cancelado
							</b-badge>
							<b-badge pill variant="light-warning" v-else>
								Em andamento
							</b-badge>
						</div>
						<div class="col-2 col-md-1 d-flex justify-content-end px-0">
							<feather-icon class="m-0" icon="ChevronDownIcon" size="20" />
						</div>
					</b-card-header>

					<b-collapse
						:id="'accordion-' + solicitacao.num_proces"
						accordion="my-accordion"
						role="tabpanel"
					>
						<div class="ml-2 d-block d-md-none">
							Status:
							<b-badge pill variant="light-success" v-if="solicitacao.is_finished">
								Concluído
							</b-badge>
							<b-badge pill variant="light-danger" v-else-if="solicitacao.is_canceled">
								Cancelado
							</b-badge>
							<b-badge pill variant="light-warning" v-else>
								Em andamento
							</b-badge>
						</div>

						<b-card-body>
							<app-timeline>
								<app-timeline-item
									v-for="(etapa, index) in solicitacao.historic"
									:key="index"
									:title="etapa.etapa_name"
									:icon="etapaIcon(etapa)"
									:subtitle="etapa.etapa_desc"
									:time="formatDate(etapa.data_execucao)"
									:variant="etapaVariant(etapa)"
								/>
							</app-timeline>
						</b-card-body>
					</b-collapse>
				</b-card>
			</template>
			<template v-else>
				<BCardBody class="d-flex justify-content-center align-items-center mt-5">
					Não há solicitações para esse contrato
				</BCardBody>
			</template>
		</template>
		<template v-else>
			<BCardBody class="d-flex justify-content-center align-items-center mt-5">
				<b-spinner class="mx-auto my-5" />
			</BCardBody>
		</template>
	</div>
</template>

<script>
import Vue from 'vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import SelectContract from '@/views/components/selectContract.vue'
import SelectContractLabel from '@/views/components/selectContractLabel.vue'
import services from '@/services'
import store from '@/store'
import moment from 'moment'
import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)
export default {
	name: 'Solicitacoes',
	components: {
		SelectContract,
		SelectContractLabel,
		AppTimelineItem,
		AppTimeline,
	},

	data() {
		return {
			solicitacoes: [],
			isLoading: false,
		}
	},

	computed: {
		selectedContract() {
			return store.state.contracts.selectedContract
		},
	},

	mounted() {
		this.getSolicitacoes()
	},

	methods: {
		async getSolicitacoes() {
			this.isLoading = true
			this.solicitacoes = await services.process.getProcesses(
				this.$apolloProvider.defaultClient
			)
			this.isLoading = false
		},

		formatDate(date) {
			return moment(date).format('DD/MM/YYYY HH:mm')
		},

		etapaIcon(etapa) {
			if (etapa.is_end) {
				return 'AwardIcon'
			} else if (etapa.is_start) {
				return 'PlayCircleIcon'
			} else if (etapa.is_running) {
				return 'ClockIcon'
			} else if (etapa.is_canceled) {
				return 'XCircleIcon'
			} else {
				return 'CheckCircleIcon'
			}
		},

		etapaVariant(etapa) {
			if (etapa.is_end) {
				return 'success'
			} else if (etapa.is_start) {
				return 'info'
			} else if (etapa.is_running) {
				return 'warning'
			} else if (etapa.is_canceled) {
				return 'danger'
			} else {
				return 'primary'
			}
		},
	},

	watch: {
		selectedContract: {
			handler() {
				this.getSolicitacoes()
			},
		},
	},
}
</script>
